import React, { useState, useEffect } from 'react';
import WordCard from './WordCard';
import './HomePage.css';

function HomePage({ words }) {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isFlipped, setIsFlipped] = useState(false);

  useEffect(() => {
    const setBodyHeight = () => {
      const element = document.getElementById('home-page');
      element.style.height = `${window.innerHeight}px`;
    };
  
    setBodyHeight();
    window.addEventListener('resize', setBodyHeight);
  
    // Cleanup function
    return () => {
      window.removeEventListener('resize', setBodyHeight);
    };
  }, []);


  const handleClick = () => {
    setIsFlipped(!isFlipped);
  };

  const handleCheck = (event) => {
    event.stopPropagation();
    // Move to the next word
    if (currentIndex < words.length - 1) {
      setCurrentIndex(currentIndex + 1);
      setIsFlipped(false);
    }
  };

  const handleCross = (event) => {
    event.stopPropagation();
    // Move to the previous word
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
      setIsFlipped(false);
    }
  };

  return (
    <div className="home-page" id="home-page">
      <div className="card-container" onClick={handleClick}>
        <WordCard word={words[currentIndex].word} definition={words[currentIndex].def} isFlipped={isFlipped} />
      </div>

      <div className="button-bar">
        <button class="button" onClick={handleCross}>Previous</button>
        <button class="button" onClick={handleCheck}>Next</button>
      </div>
    </div>
  );
}

export default HomePage;
import React from 'react';
import ReactCardFlip from 'react-card-flip';

function WordCard({ word, definition, isFlipped }) {
  return (
    <ReactCardFlip isFlipped={isFlipped} flipDirection="vertical">
      <div>
        {word}
      </div>

      <div>
        {definition}
      </div>
    </ReactCardFlip>
  );
}

export default WordCard;
import './App.css';
import HomePage from './components/HomePage';

function App() {
  const words = [
    { word: 'Word 1', def: 'A representative form or pattern 1' },
    { word: 'Word 2', def: 'A representative form or pattern 2' },
    { word: 'Word 3', def: 'A representative form or pattern 3' },
    { word: 'Word 4', def: 'A representative form or pattern 4' },
    { word: 'Word 5', def: 'A representative form or pattern 5' },
    // Add more words here...
  ];

  return (
    <div className="App">
      <header className="App-header">
        <div className="App">
          <HomePage words={words} />
        </div>
      </header>
    </div>
  );
}

export default App;
